import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Looks3, Looks4, LooksOne, LooksTwo } from '@mui/icons-material';
import { Card, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
// Styled Paper component
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'lato'
}));

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 1500,
  background: 'linear-gradient(135deg, #01647B, #0097AF)',
  marginLeft: theme.breakpoints.down('sm') ? '0rem' : '3rem',
  marginTop: '-2rem',
  marginBottom: '2rem',
  paddingBottom:'3rem',
  // Explicitly set border-radius for each breakpoint
  borderRadius: '80px 0 0 80px', // Default for large screens
  [theme.breakpoints.down('sm')]: {
    borderRadius: '50px 50px 0 0', }// For small screens
}));

const cardsData = [
  { details: 'is an important concern for security companies and clients, due to turnover.', title: 'Sustainable Service', icon: <LooksOne sx={{ fontSize: '50px', color: 'white' }} />, m: '1rem', bg: '#006747ff' },
  { details: 'creates greater risk for: margins errors, redundancy and non productivity.', title: 'High Reliance on Human', icon: <LooksTwo sx={{ fontSize: '50px', color: 'white' }} />, m: '1rem', bg: '#006747ff' },
  { details: 'is a direct result of Supervision / human efforts to maintain standards and procedures.', title: 'Efficiency & Effectiviness', icon: <Looks3 sx={{ fontSize: '50px', color: 'white' }} />, m: '1rem', bg: '#006747ff' },
  { details: 'on workforce and operation cycle does not exist.', icon: <Looks4 sx={{ fontSize: '50px', color: 'white' }} />, title: 'Real Time Visibility and Transparency', m: '1rem', bg: '#006747ff' },
];

export default function Problems() {
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const [titleVisible, setTitleVisible] = React.useState(false);
  const [detailsVisible, setDetailsVisible] = React.useState(false);

  React.useEffect(() => {
    const section = document.getElementById('challenges');
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTitleVisible(true);
          setTimeout(() => {
            setDetailsVisible(true);
          }, 100); // Adjust delay for sequential appearance
        }
      },
      { threshold: 0.5 }
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  return (
    <Box id='challenges' sx={{ flexGrow: 1, marginLeft: { xs: '0rem', md: '4rem' }, marginRight: { xs: '0rem', md: '0rem' }, direction:isRTL?'rtl':'ltr',}}>
      <StyledCard>
      <Typography
  variant='h3'
  sx={{
    fontFamily: isRTL ? 'Cairo' : 'Source Sans 3',
    fontSize: { xs: '22px', md: '35px' },
    fontWeight: 'bold',
    marginBottom: '2rem',
    marginTop: { xs: '8rem', md: '3rem' },
    color: 'white',
    opacity: titleVisible ? 1 : 0,
    transform: titleVisible ? 'translateX(0)' : 'translateX(-50px)',
    transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
    paddingTop: { xs: '0rem', md: '3rem' },
  }}
>
  {isRTL ? (
    <>
      <span style={{ color: titleVisible ? '#ff914d' : 'white' }}>{t("Challenges")}</span> {t("Critical")} {t("in Security Workforce Management")}
    </>
  ) : (
    <>
      {t("Critical")} <span style={{ color: titleVisible ? '#ff914d' : 'white' }}>{t("Challenges")}</span> {t("in Security Workforce Management")}
    </>
  )}
</Typography>


        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 12 }}>
          {cardsData.map((item, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box sx={{ margin: '1rem 4rem', textAlign: 'center' }}>
                    <Typography sx={{ fontSize: '50px', color: 'white' }}>{item.icon}</Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        marginTop: 1,
                        color: 'white',
                        fontWeight: 'bold',
                        fontFamily: isRTL?'Cairo':  'lato',
                        fontSize: { xs: '16px', md: '17px' },
                        opacity: detailsVisible ? 1 : 0,
                        transform: detailsVisible ? 'translateX(0)' : 'translateX(-50px)',
                        transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
                        transitionDelay: `${index * 100}ms`,
                      }}
                    >
                      {t(item.title)}
                    </Typography>
                    <Typography
                      sx={{
                        marginTop: 0.5,
                        color: 'white',
                        marginBottom: { sm: '3rem', xs: '2.5rem', md: '2rem' },
                        fontFamily: isRTL?'Cairo':  'lato',
                        fontSize: { xs: '14px', md: '16px' },
                        opacity: detailsVisible ? 1 : 0,
                        transform: detailsVisible ? 'translateX(0)' : 'translateX(-50px)',
                        transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
                        transitionDelay: `${index * 100 + 200}ms`,
                      }}
                    >
                      {t(item.details)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </StyledCard>
    </Box>
  );
}
