import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Computer, Security, Layers, IntegrationInstructions } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
const cardData = [
  {
    title: "Digital Transformation",
    content: "Digital transformation to the most raw & dynamic sector, revolutionizing traditional practices with cutting-edge technology and innovative solutions.",
    icon: <Computer sx={{ fontSize: '30px' }} />,
  },
  {
    title: "Enabling Security Workforce Industry",
    content: "Our solution enables clients & industry practitioners to boost workforce & operation management, increasing value proposition and focusing on core business.",
    icon: <Security sx={{ fontSize: '30px' }} />,
  },
  {
    title: "An Industrial Blanket",
    content: "We integrate best practices & different technologies in one solution, covering the entire workflow cycle while adding synergistic modules.",
    icon: <Layers sx={{ fontSize: '30px' }} />,
  },
  {
    title: "Easy to Use and Integrate",
    content: "Easy to use and highly integratable with existing security technologies, providing seamless functionality and minimizing operational disruptions.",
    icon: <IntegrationInstructions sx={{ fontSize: '30px' }} />,
  },
];

const WhyChooseUs = () => {
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';
  return (
    <div id='about' style={{  paddingBottom: '7rem',direction:isRTL?'rtl':'ltr' }}>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.2, ease: 'easeOut' }}
        viewport={{ once: true }}
      >
        <Typography
          sx={{
            fontSize: { xs: '25px', md: '32px' },
       fontFamily: isRTL ? 'Cairo' : 'Source Sans 3',
            marginTop: '7rem',
            fontWeight: 'bold',
            marginBottom: '4rem',
            color: '#024158',
            paddingTop: '6rem',
            textAlign: 'center',
            position: 'relative',
          }}
          variant="h4"
          gutterBottom
          align="center"
        >
          {t("Why Choose Us?")}
        </Typography>
      </motion.div>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'center',
          mt: 10,
          margin: { xs: '3rem', md: '6rem 3rem' },
        }}
      >
        {cardData.map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 1.2,
              delay: index * 0.2,
              ease: 'easeOut',
            }}
            viewport={{ once: true }}
          >
            <Card
              sx={{
                borderRadius: '15px',
                border: '1px solid #0097AF',
                maxWidth: 300,
                width: '100%',
                boxShadow: 3,
                background: 'white',
                height: { xs: '275px', md: '300px' },
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  color: '#023047',
                }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 'auto auto',
                    marginTop: '1rem',
                  }}
                >
                  <Box sx={{ color: '#0097AF', textAlign: 'center' }}>
                    {item.icon}
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: '700',
                      fontFamily: isRTL ? 'Cairo' : 'lato',
                      marginTop: '1rem',
                      fontSize: { xs: '17px', md: '20px' },
                    }}
                    variant="h6"
                    gutterBottom
                  >
                    {t(item.title)}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: isRTL ? 'Cairo' : 'lato',
                      marginBottom: '1rem',
                      fontWeight: '500',
                      fontSize: { xs: '14px', md: '16px' },
                    }}
                    variant="body1"
                  >
                    {t(item.content)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </Box>
    </div>
  );
};

export default WhyChooseUs;
