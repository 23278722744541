import React from 'react';
import { Typography, Box, CardContent, Card } from '@mui/material';
import { motion } from 'framer-motion';
import img1 from '../ASSETS/evaluation (1).png';
import img2 from '../ASSETS/increase (1).png';
import img3 from '../ASSETS/workers (3).png';
import img4 from '../ASSETS/schedule.png';
import img5 from '../ASSETS/report.png';
import img6 from '../ASSETS/data.png';
import img7 from '../ASSETS/salary (1).png';
import img8 from '../ASSETS/access-control.png';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
const cardData = [
  { icon: img1, title: 'Risk Assessments & Contracting' },
  { icon: img2, title: 'Proposals & Sales' },
  { icon: img3, title: 'Recruitment, Training, Projects & Manpower' },
  { icon: img4, title: 'Scheduling, Attendance & Guard Tours' },
  { icon: img5, title: 'Incident Reporting & KPI' },
  { icon: img6, title: 'Data Analytics & Reporting' },
  { icon: img7, title: 'Payroll & Invoicing' },
  { icon: img8, title: 'Access Control' },
];

const Features = () => {
  const { t } = useTranslation();
    const isRTL = i18n.language === 'ar';
  return (
    <Box sx={{ margin: '10rem 1rem',direction:isRTL?'rtl':'ltr', }}>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 2, ease: 'easeInOut' }}
        viewport={{ once: true }}
      >
        <Typography
  sx={{
    fontSize: { xs: '20px', md: '32px' },
    fontFamily: isRTL ? 'Cairo' : 'Source Sans 3',
    marginTop: { xs: '1rem', md: '10rem' },
    fontWeight: 'bold',
    marginBottom: { xs: '0rem', md: '0rem' },
    color: '#024158',
    paddingTop: '0rem',
    textAlign: 'center',
  }}
  variant="h4"
  gutterBottom
  align="center"
>
  {isRTL ? (
    <>
      <span style={{ color: '#ff914d' }}>{t("Features")}</span> {t("Comprehensive Security Workforce Management")}
    </>
  ) : (
    <>
      {t("Comprehensive Security Workforce Management")} <span style={{ color: '#ff914d' }}> {t("Features")}</span>
    </>
  )}
</Typography>

      </motion.div>

      <motion.div
        initial={{ x: 100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        viewport={{ once: true }}
      >
        <Typography
          sx={{
            fontSize: { xs: '14px', md: '25px' },
            fontFamily: isRTL?'Cairo':  'lato',
            margin: { xs: '1rem 2rem', md: '2rem 8rem' },
            fontWeight: 'bold',
            marginBottom: { xs: '6rem', md: '4rem' },
            color: '#024158',
            paddingTop: '0rem',
            textAlign: 'center',
          }}
          variant="h4"
          gutterBottom
          align="center"
        >
          {t("A software that enables Security Companies & their Clients manage & scale Security Workforce management by Simplifying")}
        </Typography>
      </motion.div>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          flexWrap: 'wrap',
          gap: 1,
          justifyContent: 'center',
          mt: 5,
          margin: { xs: '1rem', md: '3rem 3rem' },
          paddingBottom: { xs: '0rem', md: '4rem' },
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {cardData.map((item, index) => (
          <motion.div
            key={index}
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              type: 'spring',
              stiffness: 70,
              delay: index * 0.1,
            }}
            viewport={{ once: true }}
            sx={{
              borderRadius: '15px',
              maxWidth: { xs: 400, md: 300 },
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Card
              sx={{
                borderRadius: '5px',
                maxWidth: 300,
                width: '300px',
                boxShadow: 3,
                background: 'linear-gradient(135deg, #f6f6f6, #E7EDEF)',
                height: { xs: '155px', md: '200px' },
                marginBottom:{xs:'1rem',md:'0rem'}
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  color: '#023047',
                }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 'auto auto',
                    marginTop: { xs: '0.5rem', md: '1rem' },
                  }}
                >
                  <img src={item.icon} style={{ width: '60px', height: '50px' }} />
                  <Typography
                    sx={{
                      fontWeight: '700',
                      fontFamily: isRTL?'Cairo':  'lato',
                      marginTop: '1rem',
                      fontSize: { xs: '15px', md: '18px' },
                    }}
                    variant="h6"
                    gutterBottom
                  >
                    {t(item.title)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </Box>
    </Box>
  );
};

export default Features;
