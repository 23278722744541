import React from 'react';
import { Box, Button, Grid, Typography, keyframes } from "@mui/material";
import { styled } from '@mui/system';
import hero from '../ASSETS/headerff.png';
import smallHero from '../ASSETS/smallh.png'; // Import the small screen image
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Scroll } from '../../Scroll';
import { HashLink as Link } from 'react-router-hash-link';

const navItems = [
    { label: 'Solutions', path: '#solutions' },
    { label: 'Challenges', path: '#challenges' },
    { label: 'Why us', path: '#about' },
    { label: 'Contact', path: '#contact' }
];

// Define the slide-in animation using keyframes
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Create styled components for Typography and Button with the slide-in animation
const AnimatedTypography = styled(Typography)(({ theme }) => ({
  animation: `${slideIn} 1.5s ease-in-out`,
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  animation: `${slideIn} 2s ease-in-out`,
}));

export default function Header() {
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';

  return (
    <Box
      sx={{
        position: 'relative',
        height: { xs: 'auto', md: '750px' }, // Adjust height for smaller screens
        margin: { xs: '0rem', md: '5.4rem 0rem' }, // Responsive margins
        overflow: 'hidden',
        padding: { xs: '1rem', md: '0' }, // Padding for smaller screens
        textAlign: { xs: 'center', md: 'left' }, // Align text to the left on larger screens
        '@media (min-width: 900px) and (max-width: 1000px)': {
          height: '670px', // Adjusted height for screens between 900px and 1200px
          padding: '0 0rem',
          margin:'6rem 0rem' // Adjust padding for this range
        },
        '@media (min-width: 1001px) and (max-width: 1200px)': {
          height: '650px', // Adjusted height for screens between 900px and 1200px
          padding: '0 0rem',
          margin:'8rem 0rem' // Adjust padding for this range
        },
        '@media (min-width: 1201px) and (max-width: 1350px)': {
            height: '850px', // Adjusted height for screens between 900px and 1200px
            padding: '0 0rem',
            margin:'8rem 0rem' // Adjust padding for this range
          },
      }}
    >
      {/* Background Image */}
      <Box
        component="img"
        src={hero}
        alt="Background"
        sx={{
          display: { xs: 'none', md: 'block' }, // Hide on small screens
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      <Box
        component="img"
        src={smallHero}
        alt="Small Screen Background"
        sx={{
          display: { xs: 'block', md: 'none' }, // Show on small screens
          width: '45%',
          height: '45%',
          justifyContent: 'center',
          alignItems: 'center',
          margin: { sm: '6rem auto', xs:isRTL?'2rem auto': 'auto auto' },
        }}
      />

      {/* Content */}
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'flex-start' }} // Center content on smaller screens and align to the left on larger screens
        sx={{
          position: 'relative',
          zIndex: 2,
          height: '100%',
          padding: { xs: '2rem', md: '7rem 5rem 2rem 9rem' }, // Responsive padding
          textAlign: { xs: 'center', md: 'left' }, // Align text to the left on larger screens
          '@media (min-width: 900px) and (max-width: 1200px)': {
            padding: '5rem 4rem', // Adjust padding for screens between 900px and 1200px
          },
        }}
      >
        {/* Title, Paragraph, and Button in a Column */}
        <Grid
          item
          xs={16}
          md={8}
          container
          direction="column"
          spacing={2}
          sx={{
            padding: { xs: '0', md: '0 8rem' },
            textAlign: { xs: 'center', md: 'left' }, // Align text to the left on larger screens
            '@media (min-width: 900px) and (max-width: 1200px)': {
              padding: '0 4rem' // Adjust padding for screens between 900px and 1200px
            },
          }}
          order={{ xs: 2, md: 1 }} // Order on small screens
        >
          {/* Title */}
          <Grid sx={{direction:isRTL?'rtl':'ltr',textAlign:{xs:isRTL?'center':'center',md:isRTL?'center':'left'}}} item order={{ xs: 1, md: 1 }}>
            <AnimatedTypography
              variant="h4"
              sx={{
                color: '#023047',
                fontFamily: isRTL?'Cairo':  'Source Sans 3',
                fontWeight: 900,
                fontSize: { xs:isRTL?'38px': '34px', sm: '36px', md:isRTL?'66px': '57px' }, // Responsive font size
                marginBottom: { xs: '0rem', md: '0rem' },
                marginTop: { xs: '0rem', md: '1rem', sm: '-3rem' },
                '@media (min-width: 900px) and (max-width: 1200px)': {
                  fontSize:isRTL?'65px': '48px',
                  direction:isRTL?'rtl':'ltr'  // Adjust font size for screens between 900px and 1200px
                },
              }}
            >
              {t("Innovative Solutions for Security Management")}
            </AnimatedTypography>
          </Grid>

          {/* Description */}
          <Grid sx={{direction:isRTL?'rtl':'ltr',textAlign:{xs:isRTL?'center':'center',md:isRTL?'right':'left'}}}item order={{ xs: 2, md: 3 }}>
            <AnimatedTypography
              sx={{
                color: '#023047',
                fontFamily: isRTL?'Cairo':  'lato',
                fontSize: { xs: '13px', md: '19px' }, // Responsive font size
                marginBottom: '1rem', // Adjust margin
                fontWeight: '500',
                marginTop:{xs:isRTL?'1rem':'1rem',md:isRTL?'9rem':'5rem'},
               /*  marginTop: { xs: '1rem', md: '5rem' }, */
                paddingRight: { xs: '0rem', md: '10rem' },
                '@media (min-width: 900px) and (max-width: 1200px)': {
                  fontSize: '16px', // Adjust font size for screens between 900px and 1200px
                  marginTop:isRTL?'6rem': '3rem',
                   // Adjust margin-top for screens between 900px and 1200px
                },
              }}
            >
              {t("Optimize efficiency and ensure reliability with our advanced workforce management solution. Streamline scheduling, enhance communication, and leverage real-time data for superior operational control and client satisfaction.")}
            </AnimatedTypography>
          </Grid>

          {/* Button */}
          <Grid sx={{direction:isRTL?'rtl':'ltr',textAlign:{xs:isRTL?'center':'center',md:isRTL?'center':'left'}}} item order={{ xs: 3, md: 2 }}>
            <AnimatedButton
              key={navItems.label}
              component={Link}
              to="#solutions"
              scroll={(el) => Scroll(el)}
              smooth
              sx={{
               fontFamily: isRTL?'Cairo':  'Source Sans 3',
                fontSize: { xs: '12px', md: '15px' }, // Responsive font size
                backgroundColor: '#0097b2',
                borderRadius: '30px',
                color: 'white',
                fontWeight: 'bold',
                padding: { xs: '0.6rem 2rem', md: '0.9rem 4rem' }, // Responsive padding
                border: '1px solid #0097b2',
                marginTop: { xs: '0rem', md: '2rem' },
                '&:hover': {
                  backgroundColor: '#A0D6DF',
                  color: '#3E1703',
                  border: '1px solid transparent',
                },
                '@media (min-width: 900px) and (max-width: 1200px)': {
                  fontSize: '14px', // Adjust font size for screens between 900px and 1200px
                  padding: '0.7rem 3rem',
                  // Adjust padding for screens between 900px and 1200px
                },
              }}
            >
              {t("Get Started")}
            </AnimatedButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
