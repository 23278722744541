import './App.css';
import Navbar from './COMPONENTS/NAVBAR/Navbar';
import Header from './COMPONENTS/HEADER/Header';
import About from './COMPONENTS/WHYChooseUs/WhyChooseUs.jsx';
import Solutions from './COMPONENTS/SOLUTIONS/Solutions';
import Problems from './COMPONENTS/PROBLEMS/Problems';
import Contact from './COMPONENTS/CONTACT/Contact';
import CusNeeds from './COMPONENTS/CUSTOMER/CusNeeds.jsx';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Features from './COMPONENTS/FEATURES/Features.jsx';
import WhyChooseUs from './COMPONENTS/WHYChooseUs/WhyChooseUs.jsx';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <>
              <Navbar />
              <Header />
             <Features/>
              <Solutions />
              <Problems />
             <CusNeeds/>
             <WhyChooseUs/>
             <Contact />
            </>
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
