import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {motion} from 'framer-motion'
import needs from '../ASSETS/needs.png';
import img from '../ASSETS/imgcus.png'
import { Typography ,keyframes} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
// Define the slide-in animation using keyframes
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Create styled components for Typography with the slide-in animation
const AnimatedTypography = styled(Typography)(({ theme }) => ({
  animation: `${slideIn} 1.5s ease-in-out`,
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function CusNeeds() {
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';
  return (
    <Box sx={{ flexGrow: 1 ,marginTop:'8rem',direction:isRTL?'rtl':'ltr',textAlign:isRTL?'right':'left'}}>
      <Grid container spacing={2} columns={16}>
        <Grid  item xs={16} md={8} direction="column"order={{ xs: 1, md: 1 }} >
          <Box sx={{ marginLeft:{xs:'0rem',md:'8rem'},}}>
        <Grid  item>     
           <motion.div
        initial={{ x: -100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.2, ease: 'easeOut' }}
        viewport={{ once: true }}
      >
           
              <Typography variant="h4"
              sx={{
                color: '#023047',
                fontFamily: isRTL ? 'Cairo' : 'Source Sans 3',
                fontWeight: 800,
                fontSize: { xs: '29px', sm: '36px', md: '58px' }, // Responsive font size
                marginBottom: { xs: '3rem', md: '0rem' },
                marginTop:{xs:'3rem',md:'13rem'},
                
               textAlign:{xs:isRTL?'center':'center',md:isRTL?'right':'left'} ,
                marginRight: {xs:isRTL?'2rem':'2rem',md:isRTL?'2rem':'1rem'},
               marginLeft:'1rem',
               '@media (min-width: 900px) and (max-width: 1100px)': {
         marginTop:'5rem',
         fontSize:'35px',
        },
        '@media (min-width: 1101px) and (max-width: 1200px)': {
          marginTop:'5rem',
          fontSize:'40px' // Adjust padding for this range
        },
        '@media (min-width: 1201px) and (max-width: 1550px)': {
          marginTop:'8rem',
          marginRight:'8rem',
           // Adjust padding for this range
        }
    
              }}
            >
              {t("Understanding Customer Needs")}
              </Typography>
          </motion.div>
          </Grid>

          {/* Description */}
          <Grid item>
          <motion.div
        initial={{ x: 100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.2, ease: 'easeOut' }}
        viewport={{ once: true }}
      >
            <Typography
              sx={{
                color: '#023047',
                fontFamily: isRTL ? 'Cairo' : 'lato',
                fontSize: { xs: '13px', md: '19px' }, // Responsive font size
                marginBottom: '3rem', // Adjust margin
                fontWeight: '500',
               marginRight:'1rem',
               marginLeft:'1rem',
                marginTop: { xs: '1rem', md: '2rem' },
                paddingRight: { xs: '0rem', md: '6rem' },
                
                '@media (min-width: 900px) and (max-width: 1100px)': {
                  marginTop:'2rem',
                  fontSize:'17px',paddingRight:'0rem'
                 },
                 '@media (min-width: 1101px) and (max-width: 1200px)': {
                   marginTop:'2rem',
                   fontSize:'18px',paddingRight:'0rem' // Adjust padding for this range
                 },
               textAlign:{xs:isRTL?'center':'center',md:isRTL?'right':'left'}
              }}
            >
              {t("Our solution is crafted to address the key challenges our customers face, such as efficiency, cost optimization, and performance monitoring. By focusing on these critical areas, we help our clients achieve sustainable growth and success.")}
            </Typography></motion.div>
          </Grid></Box>
        </Grid>
       
        <Grid order={{ xs: 2, md: 2 }} item xs={16} md={8}>
        <Box
            component="img"
            src={isRTL ? img : needs}
            alt="Background"
            sx={{
              width: '100%',
              height: 'auto',
              maxWidth: { xs: '100%', md: '80%' }, // Responsive max-width for the image
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}







