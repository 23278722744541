import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Paper, Typography, keyframes } from '@mui/material';
import { Instagram, LinkedIn, X, Email, Phone } from '@mui/icons-material';
import logo from '../ASSETS/alert (4).png'; // Import the small screen image
import { HashLink as Link } from 'react-router-hash-link';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const navItems = [
  { label: 'Solutions', path: '#solutions' },
  { label: 'Challenges', path: '#challenges' },
  { label: 'Why choose us', path: '#about' },
  { label: 'Contact', path: '#contact' }
];

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const CustomButton = styled('a')(({ theme, selected }) => ({
  color: selected ? 'white' : 'white',
  margin: '0.5rem 0',
  textAlign: 'center',
  
  fontWeight: '500',
  textDecoration: 'underline',
  fontSize: '18px',
  padding: '0.5rem 1.5rem',
  borderRadius: '20px',
  border: selected ? '1px solid #0097AF' : 'none',
  backgroundColor: selected ? '#0097AF' : 'transparent',
  '&:hover': {
    backgroundColor: selected ? '#007B93' : 'rgba(0, 151, 175, 0.1)',
  },
}));

const AnimatedTypography = styled(Typography)(({ theme }) => ({
  animation: `${slideIn} 1.5s ease-in-out`,
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Contact() {
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';
  return (
    <div style={{direction:isRTL?'rtl':'ltr'}}>
      <Box
        id='contact'
        sx={{
          flexGrow: 1,
          background: 'linear-gradient(135deg, #01647B, #0097AF)',
          color: '#454461',
          padding: { xs: '2rem', md: '4rem' },
          borderRadius: '50px 50px 0 0',
        }}
      >
        <Grid
          container
          spacing={2}
          columns={12}
          sx={{
            textAlign: { xs: 'center', md: 'center' },
            marginLeft: { xs: '0rem', md: '1rem' },
            '@media (min-width:900px) and (max-width:1100px)': {
              flexDirection: 'column',
              margin:'auto auto',
              textAlign:'center',
              justifyContent:'center',
              alignItems:'center'
            },
          }}
        >
          <Grid item xs={12} sm={12} md={3} sx={{ order: { xs: 1, md: 1, sm: 1 } }}>
            <Box sx={{ width: { xs: '80%', md: '100%', sm: '50%' }, margin: { xs:isRTL?'2rem': '0rem', md: '0rem', sm: 'auto' } }}>
              <img src={logo} style={{ width: '100%', height: 'auto', marginTop: '0rem' }} alt="Logo" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} sx={{ order: { xs: 2, md: 2, sm: 2 }, marginRight: { xs:isRTL?'1rem': '2rem', md: '0rem' } }}>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: 'easeInOut' }}
              viewport={{ once: true }}
            >
              <Typography
                sx={{
                  color: 'white',
                fontFamily: isRTL ? 'Cairo' : 'Source Sans 3',
                  marginTop: { xs: '-3rem', md: '5rem', sm: '0rem' },
                  marginBottom: '1rem',
                  fontWeight: 'bold',
                  fontSize: { xs: '1.2rem', md: '2rem' }
                }}
                variant='h5'
              >
                {t("Quick Links")}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column' }, gap: '0.5rem', alignItems: 'center' }}>
                {navItems.map((item) => (
                  <Link
                    to={item.path}
                    smooth
                    style={{ textDecoration: 'none' }}
                    key={item.label}
                  >
                    <CustomButton sx={{ fontFamily: isRTL?'Cairo':  'Source Sans 3',}}>
                      {t(item.label)}
                    </CustomButton>
                  </Link>
                ))}
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={12} md={3} sx={{ order: { xs: 3, md: 3, sm: 3 }, marginRight: { xs:isRTL?'1rem': '2rem',  md: '0rem' } }}>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: 'easeInOut' }}
              viewport={{ once: true }}
            >
              <a style={{ color: 'white', cursor: 'pointer', textDecoration: 'none' }} href='tel:0540008700'>
                <Typography
                  sx={{
                    fontFamily: isRTL?'Cairo':  'Source Sans 3',
                    marginTop: { xs: '2rem', md: '5rem' },
                    marginBottom: '1rem',
                    fontWeight: 'bold',
                    fontSize: { xs: '1.2rem', md: '2rem' }
                  }}
                  variant='h5'
                >
                  {t("Contact us")}
                </Typography>
                <AnimatedTypography sx={{  fontFamily: isRTL?'Cairo':  'lato', fontSize: { xs: '0.9rem', md: '1.5rem' } }}>
                  <Phone sx={{ color: 'white', fontSize: { xs: '1.5rem', md: '1.5rem' }, marginRight:isRTL? '0rem':'1rem'  , marginLeft:isRTL? '1rem':'0rem'}} />
                  0540008700
                </AnimatedTypography>
              </a>
              <a style={{ color: 'white', cursor: 'pointer', textDecoration: 'none' }} href='mailto:connect@alertsolution.net'>
                <AnimatedTypography sx={{  fontFamily: isRTL?'Cairo':  'lato',fontSize: { xs: '0.9rem', md: '1.5rem' } }}>
                  <Email sx={{ color: 'white', fontSize: { xs: '1.5rem', md: '1.5rem' },marginRight:isRTL? '0rem':'1rem',marginLeft:isRTL? '1rem':'0rem' }} />
                  connect@alertsolution.net
                </AnimatedTypography>
              </a>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={12} md={3} sx={{ order: { xs: 4, md: 4, sm: 4 }, marginRight: { xs:isRTL?'1rem': '2rem',  md: '0rem' } }}>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: 'easeInOut' }}
              viewport={{ once: true }}
            >
              <Typography
                sx={{
                  fontFamily: isRTL?'Cairo':  'Source Sans 3',
                  color: 'white',
                  marginTop: { xs: '2rem', md: '5rem' },
                  marginBottom: { xs: '1rem', md: '3rem' },
                  fontWeight: 'bold',
                  fontSize: { xs: '1.2rem', md: '2rem' }
                }}
                variant='h5'
              >
                {t("Social Media")}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'center' }, gap: '1rem', fontSize: { xs: '1.5rem', md: '2rem' } }}>
                <a style={{ cursor: 'pointer', textDecoration: 'none' }} href=''>
                  <LinkedIn sx={{ color: 'white', fontSize: { xs: '1.5rem', md: '2rem' } }} />
                </a>
                <a style={{ cursor: 'pointer', textDecoration: 'none' }} href='https://www.instagram.com/alertsolution?utm_source=ig_web_button_share_sheet&igsh=ODdmZWVhMTFiMw=='>
                  <Instagram sx={{ color: 'white', fontSize: { xs: '1.5rem', md: '2rem' } }} />
                </a>
                <a style={{ cursor: 'pointer', textDecoration: 'none' }} href=''>
                  <X sx={{ color: 'white', fontSize: { xs: '1.5rem', md: '2rem' } }} />
                </a>
              </Box>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
