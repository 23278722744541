import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../ASSETS/alert.png';
import { HashLink as Link } from 'react-router-hash-link';
import { Scroll } from '../../Scroll';
import { List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
const drawerWidth = 240;
const navItems = [
    { label: 'Solutions' , path: '#solutions' },
    { label: 'Challenges', path: '#challenges'  },
   
    { label: 'Why choose us', path: '#about'  },
    /* { label: 'Organization', icon: <HomeIcon /> }, */
    { label: 'Contact', path: '#contact'  }
];

function Navbar(props) {
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';
  const { window: windowProp } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [lastScrollY, setLastScrollY] = React.useState(0);
  const [showNavbar, setShowNavbar] = React.useState(true);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  
 
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  
  const controlNavbar = () => {
    if (typeof window !== 'undefined') {

      if (window.scrollY > lastScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);
  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img src={logo} style={{ width: '100px', height: '110px' }} alt="Logo" />
      </Typography>
    </Box>
  );
  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
  };


  const container = windowProp !== undefined ? () => windowProp().document.body : undefined;

  return (
    <Box sx={{ display: 'block', flexDirection: 'row'  }}>
    

      {/* Logo for small screens */}
      <Box sx={{ display: { xs: 'block', sm: 'none' }, textAlign: 'left', padding: '0rem' ,marginLeft:'1rem'}}>
        <img src={logo} style={{ width: '130px', height: 'auto' }} alt="Logo" />
       
      </Box>
      <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                position: 'fixed',
                top: '0rem',
                right: '0rem',
                marginTop: '0.5rem',
                marginRight: '1rem',
                
               zIndex: 20 
              }}
            >
              <IconButton
                size="large"
                aria-label="open drawer"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerToggle}
                color="black"
              >
                <MenuIcon />
              </IconButton>
            </Box>
      <AppBar
        sx={{
          backgroundColor: '#F8FBFA',
          marginTop: '0rem',
          display: { xs: 'none', sm: 'block' }
        }}
        component="nav"
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color='#2f2f2f'
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon sx={{ color: '#2f2f2f' }} />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, marginLeft: { xs: '1rem', md: '4rem',sm:'0rem' }, marginTop: '0.5rem', textAlign: 'left' }}
          >
            <img src={logo} style={{ width: '170px', height: '90px', marginLeft: '2rem' }} alt="Logo" />
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' }, marginRight: { sm: '0rem', md: '6rem' } }}>
            {navItems.map((item) => (
              <Button
                key={item.label}
                component={Link}
                to={item.path}
                scroll={el => Scroll(el)}
                smooth
                onClick={handleDrawerToggle}
                sx={{
                  fontFamily: isRTL?'Cairo':  'Source Sans 3',
                  color: '#3E1703',
                  marginLeft: { xs: '0rem', md: '2rem' ,sm:'0rem'},
                  fontWeight: 'bold',
                  border: item.label === 'Contact' ? '1px solid #0097AF' : 'none',
                  backgroundColor: item.label === 'Contact' ? '#0097AF' : 'transparent',
                  color: item.label === 'Contact' ? 'white' : '#023047',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#023047',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#FFCB74',
                    color: '#3E1703',
                  }
                }}
              >
                {t(item.label)}
              </Button>

            ))}
            <Button
                size="large"
                onClick={() => handleLanguageChange(isRTL ? 'en' : 'ar')}
                sx={{
                  display: { xs: 'none', sm: 'inline-flex' },
                  alignItems: 'center',
                  color: '#023047',
                  ml: 6,
                  fontFamily: isRTL ? 'Rubik' : 'lato',
                  fontWeight: '500',
                  border: '1px solid #0097AF',
                  borderRadius: '5px',
                  marginLeft: '2rem',
                  marginRight: '0rem',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: '#0097AF',
                    border: '1px solid #0097AF',
                    color: 'white',
                    fontWeight: 'bold',
                  }
                }}
              >
                {isRTL ? 'EN' : 'AR'}
              </Button>
          </Box>
        </Toolbar>
      </AppBar>
      
      <nav>
        
      </nav>

      {/* Bottom Navigation for small screens */}
      {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(2, 48, 71, 0.4)', // Transparent dark blue color
            backdropFilter: 'blur(15px)', // Blur effect for background
            borderRadius: '10px',
            margin: '0.5rem 0.5rem',
            zIndex: 1300, // Ensure it appears above other elements
          }}
        >
          {navItems.map((item, index) => (
            <BottomNavigationAction
              key={index}
              label={item.label}
              icon={item.icon}
              component={Link}
              to={item.path}
              scroll={el => Scroll(el)}
              smooth
              onClick={handleDrawerToggle}
              sx={{
                color:'white',
                fontWeight:'bold',
                minWidth: '50px', // Adjust button size for small screens
                '&.Mui-selected': {
                  color: 'white',
                }
              }}
            />
          ))}
        </BottomNavigation>
      </Box> */}<Drawer
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <Box
            sx={{
              width: drawerWidth,
              backgroundColor: '#F8FBFA',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            role="presentation"
            onClick={handleDrawerToggle}
            onKeyDown={handleDrawerToggle}
          >
            {/* Logo inside the drawer */}
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <img src={logo} style={{ width: '160px', height: '90px' }} alt="Logo" />
            </Box>
            <List sx={{ width: '100%' }}>
              {navItems.map((page) => (
                <ListItem
                  button
                  key={page.label}
                  component={Link}
                  to={page.path}
                  scroll={el => Scroll(el)}
                  smooth
                  sx={{
                    justifyContent: 'center',
                    /* fontFamily: isRTL ? 'Readex Pro' : "Lato", */
                    color: '#454461',
                    '& .MuiListItemText-root': {
                      textAlign: 'center',
                      /* fontFamily: isRTL ? 'Readex Pro' : "Lato", */
                    },
                  }}
                >
                  <ListItemText primary={t(page.label)} />
                </ListItem>
              ))}
              {/* Language Button in Drawer */}
              <ListItem
                button
                sx={{
                  justifyContent: 'center',
                  fontFamily: isRTL ? 'Readex Pro' : "Lato", 
                  color: '#006747',
                  '& .MuiListItemText-root': {
                    textAlign: 'center',
                    '&:hover': {
                        backgroundColor:'#80B3A3',
                        border:'1px solid #80B3A3',
                        color:'white'
                    }
                  },
                }}
              >

<Button
                size="large"
                onClick={() => handleLanguageChange(isRTL ? 'en' : 'ar')}
                sx={{
                  display: { xs: 'flex', sm: 'inline-flex' },
                  alignItems: 'center',
                  color: 'white',
                  ml: 6,
                  fontFamily: isRTL ? 'Rubik' : 'lato',
                  fontWeight: '500',
                  border: '1px solid #0097AF',
                  borderRadius: '5px',
                  marginLeft: '4.5rem',
                  marginRight: '0rem',
                  textAlign:'center',
                  backgroundColor: '#0097AF',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    border: '1px solid #0097AF',
                    color: '#023047',
                    fontWeight: 'bold',
                  }
                }}
              >
                {isRTL ? 'EN' : 'AR'}
              </Button>
                <ListItemText primary="" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
    </Box>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
