import React, { useEffect, useRef } from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';
import one from '../ASSETS/productivity.png';
import two from '../ASSETS/salary.png';
import three from '../ASSETS/analytics.png';
import four from '../ASSETS/referral.png';
import { motion } from 'framer-motion';
import './S.css'; // Ensure the path is correct
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { t } from 'i18next';
const cardData = [
  {
    title: "Efficiency and Productivity",
    details1: "Automation: the system automates repetitive tasks, reducing the need for manual intervention and saving time."
    
    ,
    details2: 
      
          
       
        "Streamlined Workflows: simplifies complex processes, making workflows more efficient."
     ,
    details3:
     
          
   
      "Real-time Data: the availability of real-time data that can be used for quick decision-making."
   ,
   
    image: one
  },
  {
    title: "Cost Savings",
    details1:
         
        
      " Reduction in Operational Costs: the system can reduce overall operational costs through improved efficiency."
 
    ,
    details2: 
         
        " Resource Optimization: helps in better allocation and utilization of resources, leading to cost savings."
     
    ,
    details3: 
         
       
        " Minimized Errors: automation and accurate data handling can reduce costly errors."
      ,
  
    image: two
  },
  {
    title: "Data and Analytics",
    details1: 
       
         
      
      " Insightful Reports: the ability to generate detailed reports and analytics that provide valuable insights."
     
   ,
    details2: 
          
      
        "Data Integration: The ability to integrate with other systems and data sources for comprehensive analysis"

    ,
    details3:
         
        
        " Predictive Analytics: Analytics in turnover, incidents, risk score, and countermeasures help forecast trends and outcomes."
     ,
    
    image: three
  },
  {
    title: "Collaboration and Communication",
    details1: 
          
       
        "Improved Communication: the system enhances communication within teams and across departments."
     ,
    details2: 
          
       
        "Collaboration Tools: shared workspaces, file sharing, and messaging."
   
    ,
   
    image: four
  }
];
const BoldTextBeforeColon = ({ text, isRTL }) => {
  const parts = text.split(':');
  return (
    <Typography sx={{ textAlign: isRTL ? 'right' : 'left', color: '#454461', marginRight: '0rem', fontSize: { xs: '12px', md: '14px' }, fontFamily: isRTL ? 'Cairo' : 'lato' }}>
      <span style={{ fontWeight: 'bold' }}>{parts[0]}:</span>
      {parts[1] && ` ${parts[1]}`}
    </Typography>
  );
};
const CardContentWrapper = ({ title, details1, details2, details3, number, image }) => {
  const cardRef = useRef(null);
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';
  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <Card
      ref={cardRef}
      className="card-slide-up"
      sx={{
        width: {xs:'350px',md:'360px'},
        height: {xs:'325px',md:'375px'},
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: 3,
        backgroundColor: '#E3F2F4',
        color: '#000000',
        margin: '0rem 1rem',
        cursor: 'pointer',
        position: 'relative',
        padding: '0rem 0',
        borderRadius: '20px',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          
          boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
        }
      }}
    >
      <CardContent sx={{ padding: '5px', margin: '0.5rem' }}>
        <Box sx={{ width: '25%', height: 'auto', marginBottom: '0.5rem', marginTop: '0rem', textAlign: 'center', justifyContent: 'center', alignItems: 'center', margin: '0rem auto' }}>
        <motion.div
        initial={{ x: -100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.2, ease: 'easeOut' }}
        viewport={{ once: true }}
      >
          <img
            src={image}
            alt={title}
            style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
          />
          </motion.div>
        </Box>
        <Typography variant="h6" sx={{ textAlign: 'center', color: '#454461', fontWeight: 'bold', marginRight: '1rem', marginBottom: '0.5rem', marginTop: '1rem',fontSize:{xs:'15px',md:'16px'}, fontFamily: isRTL ? 'Cairo' : 'lato', }}>
          {t(title)}
        </Typography>
        <Typography sx={{ marginTop: '0.5rem', textAlign:isRTL?'right': 'left', color: '#454461', marginRight: '0rem',fontSize:{xs:'12px',md:'14px'}, fontFamily: isRTL ? 'Cairo' : 'lato', }}>
        <BoldTextBeforeColon text={t(details1)} isRTL={isRTL} />
       
        
        </Typography>
        <Typography sx={{ marginTop: '0.5rem',  textAlign:isRTL?'right': 'left', color: '#454461', marginRight: '0rem',fontSize:{xs:'12px',md:'14px'}, fontFamily: isRTL ? 'Cairo' : 'lato', }}>
        <BoldTextBeforeColon text={t(details2)} isRTL={isRTL} />
        </Typography>
        <Typography sx={{ marginTop: '0.5rem',  textAlign:isRTL?'right': 'left', color: '#454461', marginRight: '0rem',fontSize:{xs:'12px',md:'14px'} ,fontFamily: isRTL ? 'Cairo' : 'lato', }}>
        <BoldTextBeforeColon text={t(details3)} isRTL={isRTL} />
        </Typography>
      </CardContent>
    </Card>
  );
};

const SolutionCards = () => {
  const titleRef = useRef(null);
  const { t } = useTranslation();
  const isRTL = i18n.language === 'ar';

  useEffect(() => {
    const observerOptions = {
      threshold: 0.8,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <div style={{ backgroundColor: '#f6f6f6', direction: isRTL ? 'rtl' : 'ltr' }}>
      <Box id='solutions' sx={{ marginBottom: '2rem' }}>
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          viewport={{ once: true }}
        >
          <Typography
            ref={titleRef}
            sx={{
              fontSize: { xs: '20px', md: '30px' },
              fontFamily: isRTL ? 'Cairo' : 'Source Sans 3',
              marginTop: '3rem',
              fontWeight: 'bold',
              marginBottom: '4rem',
              color: '#024158',
              paddingTop: '6rem',
            }}
            variant="h4"
            gutterBottom
            align="center"
          >
            {isRTL ? (
              <>
                <span style={{ color: '#ff914d' }}>{t("Solutions")}</span> {t("Innovative")} {t("for Optimizing Security Workforce Management")}
              </>
            ) : (
              <>
                {t("Innovative")} <span style={{ color: '#ff914d' }}> {t("Solutions")}</span> {t("for Optimizing Security Workforce Management")}
              </>
            )}
          </Typography>
        </motion.div>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '0rem',
          }}
        >
          <Grid sx={{ marginBottom: '6rem' }} container spacing={2}>
            {cardData.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: { xs: (index % 2 === 0) ? 'flex-start' : 'flex-start', md: (index % 2 === 0) ? 'flex-end' : 'flex-start' },
                  marginBottom: '1rem',
                }}
              >
                <CardContentWrapper
                  title={t(item.title)}
                  details1={t(item.details1)}
                  details2={t(item.details2)}
                  details3={t(item.details3)}
                  image={item.image}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
};
export default SolutionCards;